import { BooleanOption }    from 'modules/administration/models/boolean-option';
import { BulletinRevision } from 'modules/management/models/bulletin-revision';

export class BulletinRevisionFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        test_registered_in_platform: BooleanOption.NO,
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {BulletinRevision}
     */
    model(nextBulletinRevisionNumber, nextBulletinRevisionModel) {
        let model = new BulletinRevision();

        this.modelDefaults.revision_number = nextBulletinRevisionNumber;
        this.modelDefaults.bulletin_model  = nextBulletinRevisionModel;

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.revision_number = {
            type:       'number',
            key:        'revision_number',
            label:      'form.field.revision-number',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.bulletin_model = {
            type:       'text',
            key:        'bulletin_model',
            label:      'form.field.bulletin-model',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.effective_date = {
            type:            'material-ui-date-picker',
            key:             'effective_date',
            label:           'form.field.effective-date',
            size:            viewModel.create ? 6 : 3,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        this.closed_date = {
            type:            'material-ui-date-picker',
            key:             'closed_date',
            label:           'form.field.closed-date',
            size:            3,
            required:        false,
            hidden:          viewModel.create,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        this.norm_edition = {
            type:       'text',
            key:        'norm_edition',
            label:      'form.field.norm-edition',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.test_registered_in_platform = {
            type:       'boolean-options-select',
            key:        'test_registered_in_platform',
            label:      'form.field.test-registered-in-platform',
            size:       6,
            attributes: {
                disabled: readonly,
            },
        };

        this.tests_quantity = {
            type:       'number',
            key:        'tests_quantity',
            label:      'form.field.tests-quantity',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.model_file = {
            type:       'file-dropzone',
            key:        'model_file',
            label:      'form.field.model-file',
            required:   false,
            size:       12,
            url:        viewModel.bulletinRevisionFilesRepository.uploadUri(),
            hidden:     readonly,
            mockFile:   null,
            attributes: {
                disabled: readonly,
            },
        };

        const schema = [
            [this.revision_number, this.effective_date, this.closed_date],
            [this.test_registered_in_platform, this.tests_quantity],
            [this.norm_edition, this.bulletin_model],
            [this.model_file],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
